import React from "react"
import { Link } from "@c-atoms/Link"

const css = {
    ...require("@css/atoms/a-button.module.styl"),
}

function Button({ children, to, className = "",...props}) {

    return (
        <>
        { to ?
            <Link to={to} className={`${css.aButton} ${className}`} {...props}>
                {children}
            </Link>
            :
            <span className={`${css.aButton} ${className}`} {...props}>
                {children}
            </span>
            }
        </>
    )
}

function SubmitButton({children, className, ...props}) {

    return (
        <button className={`${css.aButton} ${className}`} {...props}>
            {children}
        </button>
    )
}


export { Button, SubmitButton }