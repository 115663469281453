import React from "react"
import { Button } from "@c-atoms/Button"


import css from "@css/components/blocks/Error.module.styl"

export default function Error404()
{
	return(
		<div className={css.container}>
			<div className={css.inner}>
				<h2 className={css.title}>
					{`Not Found 404!`}
				</h2>
				<p className={css.text}>
					お探しのページが見つかりません。
				</p>
				<div className={css.buttons}>
					<Button to="/">トップへ戻る</Button>
				</div>
			</div>
		</div>
	)
}
